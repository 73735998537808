import { observer } from "mobx-react-lite";
import { ModalView } from "./common/ModalView";
import { ModalStore } from "@root/stores/modalStore";
import {
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { useState } from "react";

type ShareModalProps = {
  store: ModalStore;
  url: string;
};

export const ShareModal = observer(({ store, url }: ShareModalProps) => {
  const [copied, setCopied] = useState(false);

  return (
    <Dialog
      open={store.isOpen}
      onClose={() => store.close()}
      fullWidth={true}
      PaperProps={{
        sx: {
          borderRadius: "1rem",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 4px 16px",
          maxWidth: "36rem",
          width: "100%",
        },
      }}
    >
      <DialogContent
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          padding: "2rem",
        })}
      >
        <ModalView title="Share Event">
          <Stack gap="0.5rem">
            <TextField
              type="text"
              label="Url"
              variant="outlined"
              value={url}
              disabled={true}
              InputLabelProps={{
                shrink: true,
                sx: {
                  color: "#000 !important",
                },
              }}
              InputProps={{
                notched: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="copy"
                      onClick={() => {
                        navigator.clipboard.writeText(url);
                        setCopied(true);
                        setTimeout(() => {
                          setCopied(false);
                        }, 1500);
                      }}
                      edge="end"
                      sx={(theme) => ({
                        color: theme.palette.primary.main,
                      })}
                    >
                      <ContentCopy />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {copied && (
              <Typography
                variant="body2"
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                Share link has been copied!
              </Typography>
            )}
          </Stack>
        </ModalView>
      </DialogContent>
    </Dialog>
  );
});

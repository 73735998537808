import {
  AttachMoney,
  CalendarMonth,
  DirectionsOutlined,
  EventAvailable,
  ExploreOutlined,
  Groups,
  HomeOutlined,
  HowToReg,
  IosShare,
  LocationOnOutlined,
  NotificationsActiveOutlined,
  Person,
  ReportOutlined,
} from "@mui/icons-material";
import { Box, Button, Link, Stack, Typography, styled } from "@mui/material";
import { EventCategories } from "@root/components/common/EventCategories";
import { LoadingSpinner } from "@root/components/common/LoadingSpinner";
import { SuccessMessage } from "@root/components/common/SuccessMessage";
import { ConfirmModal } from "@root/components/modals/ConfirmModal";
import { ReportEventModal } from "@root/components/modals/ReportEventModal";
import { ShareModal } from "@root/components/modals/ShareModal";
import { eventService } from "@root/services/api/eventService";
import { userService } from "@root/services/api/userService";
import { useAccountContext } from "@root/stores/accountStore";
import { ModalStore } from "@root/stores/modalStore";
import { Event, Pricing } from "@root/utils/entities";
import {
  downloadFile,
  formatDate,
  getApiError,
  getEventShareUrl,
  getGoogleDirectionsUrl,
  getGoogleMapUrl,
  hasErrors,
} from "@root/utils/functions";
import { observer, useLocalObservable } from "mobx-react-lite";
import moment from "moment";
import { Fragment, PropsWithChildren, useState } from "react";

type EventViewProps = {
  event: Event;
};

type EventViewState = {
  isLoading: boolean;
  message?: string;
  error?: string;
  confirmTitle?: string;
  confirmMessage?: string;
  onConfirm: () => Promise<void>;
};
export const EventView = ({ event }: EventViewProps) => {
  const accountStore = useAccountContext();

  const confirmModalStore = useLocalObservable(() => new ModalStore());
  const shareModalStore = useLocalObservable(() => new ModalStore());
  const reportModalStore = useLocalObservable(() => new ModalStore());

  const [state, setState] = useState<EventViewState>({
    isLoading: false,
    onConfirm: async () => {},
  });

  const getAgeRange = (min?: number, max?: number) => {
    if (min === max) return `Age ${min}`;
    if (min === 0 && max === 65) return `All ages`;
    if (!max) return `Ages ${min} and over`;
    if (!min) return `Ages ${max} and under`;
    return `Ages ${min} to ${max}`;
  };

  const attendEvent = async () => {
    showLoadingSpinner();

    const response = await userService.attendEvent(
      accountStore.account!.id,
      event.id
    );

    if (hasErrors(response)) {
      showError(getApiError(response).message);
    } else {
      showCalendarDownloadPopup();
    }
  };

  const followEvent = async () => {
    showLoadingSpinner();

    const response = await userService.followEvent(
      accountStore.account!.id,
      event.id
    );

    if (hasErrors(response)) {
      showError(getApiError(response).message);
    } else {
      showSuccessMessage("You're now following this event.");
    }
  };

  const reportEvent = async (reason: string) => {
    showLoadingSpinner();

    const response = await userService.reportEvent(
      accountStore.account!.id,
      event.id,
      reason
    );

    if (hasErrors(response)) {
      showError(getApiError(response).message);
    } else {
      showSuccessMessage("You've reported this event.");
    }
  };

  const downloadCalendarFile = async () => {
    showLoadingSpinner();

    const response = await eventService.getCalendarFile(event.id);

    if (hasErrors(response)) {
      showError(getApiError(response).message);
    } else {
      downloadFile("event.ics", response.data!);
      hideLoadingSpinner();
    }
  };

  const showLoadingSpinner = () =>
    setState({
      ...state,
      isLoading: true,
    });

  const hideLoadingSpinner = () =>
    setState({
      ...state,
      isLoading: false,
    });

  const showSuccessMessage = (message: string) => {
    setState({
      ...state,
      message: message,
      isLoading: false,
      error: undefined,
    });

    setTimeout(() => {
      setState({
        ...state,
        message: undefined,
      });
    }, 1500);
  };

  const showError = (message?: string) =>
    setState({
      ...state,
      isLoading: false,
      error: message,
    });

  const showCalendarDownloadPopup = () => {
    setState({
      ...state,
      error: undefined,
      isLoading: false,
      confirmTitle: "Download Calendar File?",
      confirmMessage:
        "You've been added to the attendance list.\n\nWould you like to download this event's calendar file?",
      onConfirm: async () => {
        confirmModalStore.close();
        downloadCalendarFile();
      },
    });

    confirmModalStore.open();
  };

  return (
    <Stack gap="0.75rem" sx={{ paddingBottom: "2rem" }}>
      {event.bannerImageUrl && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "auto",
          }}
        >
          <img
            src={event.bannerImageUrl}
            style={{
              width: "100%",
              maxHeight: "20rem",
              objectFit: "scale-down",
              objectPosition: "center",
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "0.5rem 1rem",
          gap: "0.75rem",
        }}
      >
        <Typography
          variant="h2"
          sx={{ textAlign: "center", overflowWrap: "break-word" }}
        >
          {event.name}
        </Typography>
        <InfoRow>
          <CalendarMonth
            sx={{
              width: "1.5rem",
              height: "1.5rem",
              overflowWrap: "break-word",
            }}
          />
          <Typography
            variant="subtitle1"
            sx={{ lineHeight: "1.5rem", alignSelf: "center" }}
          >
            {formatDate(moment.utc(event.dateRange?.start).local())} -{" "}
            {formatDate(moment.utc(event.dateRange?.end).local())}
          </Typography>
        </InfoRow>

        <EventCategories event={event} />
      </Box>
      <Separator />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: state.isLoading ? "center" : "space-between",
          padding: "0.5rem 1rem",
        }}
      >
        {state.isLoading ? (
          <LoadingSpinner />
        ) : (
          <Fragment>
            <EventButton
              type="button"
              variant="text"
              onClick={() => {
                if (accountStore.account) {
                  setState({
                    ...state,
                    confirmTitle: "Attending Event?",
                    confirmMessage:
                      "Would you like to be added to the attendance list?",
                    onConfirm: async () => {
                      confirmModalStore.close();
                      attendEvent();
                    },
                  });
                  confirmModalStore.open();
                } else {
                  accountStore.authModalStore.openAuthModal("login");
                }
              }}
              startIcon={
                <EventAvailable
                  sx={{ width: "auto", height: "2rem", margin: 0 }}
                />
              }
            >
              Attend
            </EventButton>
            <EventButton
              type="button"
              variant="text"
              onClick={() => {
                if (accountStore.account) {
                  setState({
                    ...state,
                    confirmTitle: "Follow Event?",
                    confirmMessage: "Would you like to follow this event?",
                    onConfirm: async () => {
                      confirmModalStore.close();
                      followEvent();
                    },
                  });
                  confirmModalStore.open();
                } else {
                  accountStore.authModalStore.openAuthModal("login");
                }
              }}
              startIcon={
                <NotificationsActiveOutlined
                  sx={{ width: "auto", height: "2rem", margin: 0 }}
                />
              }
            >
              Follow
            </EventButton>
            <EventButton
              type="button"
              variant="text"
              onClick={() =>
                accountStore.account
                  ? window.open(
                      getGoogleDirectionsUrl(
                        accountStore.userLocation,
                        event.location!.coordinate!
                      ),
                      "_blank"
                    )
                  : accountStore.authModalStore.openAuthModal("login")
              }
              startIcon={
                <DirectionsOutlined
                  sx={{ width: "auto", height: "2rem", margin: 0 }}
                />
              }
            >
              Directions
            </EventButton>
            <EventButton
              type="button"
              variant="text"
              onClick={() =>
                accountStore.account
                  ? shareModalStore.open()
                  : accountStore.authModalStore.openAuthModal("login")
              }
              startIcon={
                <IosShare sx={{ width: "auto", height: "2rem", margin: 0 }} />
              }
            >
              Share
            </EventButton>
            <EventButton
              type="button"
              variant="text"
              onClick={() => {
                if (accountStore.account) {
                  reportModalStore.open();
                } else {
                  accountStore.authModalStore.openAuthModal("login");
                }
              }}
              startIcon={
                <ReportOutlined
                  sx={{ width: "auto", height: "2rem", margin: 0 }}
                />
              }
            >
              Report
            </EventButton>
          </Fragment>
        )}
      </Box>
      {state.message && <SuccessMessage message={state.message} />}
      <Separator />
      <Stack sx={{ gap: "0.75rem", padding: "0.5rem 1rem" }}>
        <InfoRow>
          <LocationOnOutlined sx={{ width: "1.5rem", height: "1.5rem" }} />
          <Typography
            variant="body2"
            sx={{ overflowWrap: "break-word", alignSelf: "center" }}
          >
            {event.location?.address.textDisplay}
          </Typography>
        </InfoRow>
        <InfoRow>
          <CalendarMonth sx={{ width: "1.5rem", height: "1.5rem" }} />
          <Typography
            variant="body2"
            sx={{ overflowWrap: "break-word", alignSelf: "center" }}
          >
            {formatDate(moment.utc(event.dateRange?.start).local())}
          </Typography>
        </InfoRow>
        {event.locationName && (
          <InfoRow>
            <HomeOutlined sx={{ width: "1.5rem", height: "1.5rem" }} />
            <Link
              href={getGoogleMapUrl(event.location!.coordinate)}
              target="_blank"
              sx={{ alignSelf: "center" }}
            >
              <Typography
                variant="body2"
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                {event.locationName}
              </Typography>
            </Link>
          </InfoRow>
        )}
        {event.organization ? (
          <InfoRow>
            <Groups sx={{ width: "1.5rem", height: "1.5rem" }} />
            <Typography variant="body2" sx={{ alignSelf: "center" }}>
              {event.organization?.name}
            </Typography>
          </InfoRow>
        ) : event.user?.fullName ? (
          <InfoRow>
            <Person sx={{ width: "1.5rem", height: "1.5rem" }} />
            <Typography variant="body2" sx={{ alignSelf: "center" }}>
              {event.user.fullName}
            </Typography>
          </InfoRow>
        ) : null}
        {event.eventLink && (
          <InfoRow>
            <ExploreOutlined sx={{ width: "1.5rem", height: "auto" }} />
            <Link
              href={event.eventLink}
              target="_blank"
              sx={{ alignSelf: "center", overflowWrap: "anywhere" }}
            >
              <Typography
                variant="body2"
                sx={(theme) => ({ color: theme.palette.primary.main })}
              >
                {event.eventLink}
              </Typography>
            </Link>
          </InfoRow>
        )}
        {event.pricing && event.pricing.length > 0 && (
          <InfoRow>
            <AttachMoney
              sx={{ width: "1.5rem", height: "1.5rem", alignSelf: "start" }}
            />
            <Pricings pricings={event.pricing} />
          </InfoRow>
        )}
        <InfoRow>
          <HowToReg
            sx={{ width: "1.5rem", height: "1.5rem", alignSelf: "start" }}
          />
          <Typography variant="body2" sx={{ alignSelf: "center" }}>
            {getAgeRange(event.ageRange?.min, event.ageRange?.max)}
          </Typography>
        </InfoRow>
      </Stack>
      <Separator />
      <Stack sx={{ gap: "0.75rem", padding: "0.5rem 1rem" }}>
        <SectionHeader>Event Description</SectionHeader>
        <Typography variant="body2" sx={{ overflowWrap: "break-word", whiteSpace: "pre-line" }}>
          {event.description}
        </Typography>
      </Stack>
      {event.parkingInfo && (
        <Fragment>
          <Separator />
          <Stack sx={{ gap: "0.75rem", padding: "0.5rem 1rem" }}>
            <SectionHeader>Parking</SectionHeader>
            <Typography variant="body2" sx={{ overflowWrap: "break-word", whiteSpace: "pre-line" }}>
              {event.parkingInfo}
            </Typography>
          </Stack>
        </Fragment>
      )}
      <ConfirmModal
        store={confirmModalStore}
        title={state?.confirmTitle ?? ""}
        message={state?.confirmMessage ?? ""}
        onConfirm={state.onConfirm}
        onCancel={() => confirmModalStore.close()}
      />
      <ShareModal store={shareModalStore} url={getEventShareUrl(event.id)} />
      <ReportEventModal
        store={reportModalStore}
        onSubmit={(reason) => {
          reportEvent(reason);
          reportModalStore.close();
        }}
      />
    </Stack>
  );
};

const Pricings = observer(({ pricings }: { pricings?: Pricing[] }) => (
  <Stack gap="0.75rem" sx={{ alignSelf: "center" }}>
    {pricings?.map((pricing, i) => (
      <Typography key={`eventPricing${i}`} variant="body2">
        {pricing.description}: ${pricing.price?.toFixed(2)}
      </Typography>
    ))}
  </Stack>
));

const InfoRow = styled(Box)({
  display: "flex",
  gap: "1rem",
  alignItems: "baseline",
});

const SectionHeader = ({ children }: PropsWithChildren) => (
  <Typography
    variant="body2"
    sx={{ fontWeight: 700, size: "1rem", lineHeight: "1.5rem" }}
  >
    {children}
  </Typography>
);

const Separator = styled(Box)({
  height: "2px",
  width: "100%",
  backgroundColor: "#D9D9D9",
});

const EventButton = styled(Button)(({ theme }) => ({
  flexDirection: "column",
  backgroundColor: "transparent",
  color: theme.palette.primary.main,
  "& .MuiButton-startIcon": {
    margin: 0,
  },
  textTransform: "none",
}));

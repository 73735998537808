import { StyledModal } from "./StyledModal";
import { observer } from "mobx-react-lite";
import { ColumnBox } from "../common/ColumnBox";
import { ModalView } from "./common/ModalView";
import { Fragment } from "react/jsx-runtime";
import { LoadingOverlay } from "../common/LoadingOverlay";
import { ModalStore } from "@root/stores/modalStore";
import { ReportEventForm } from "../forms/ReportEventForm";

type ReportEventModalProps = {
  store: ModalStore;
  onSubmit: (reason: string) => void;
};

export const ReportEventModal = observer(
  ({ store, onSubmit }: ReportEventModalProps) => {
    return (
      <Fragment>
        <LoadingOverlay show={store.isLoading} />
        <StyledModal open={store.isOpen} onClose={() => store.close()}>
          <ColumnBox>
            <ModalView title="Report Event">
              <ReportEventForm onFormSubmit={onSubmit} />
            </ModalView>
          </ColumnBox>
        </StyledModal>
      </Fragment>
    );
  }
);

import { FormProvider } from "react-hook-form";
import * as z from "zod";
import useFormWithValidation from "@root/hooks/useFormWithValidation";
import { Box, Button } from "@mui/material";
import { LoadingOverlay } from "../common/LoadingOverlay";
import { ErrorMessage } from "../common/ErrorMessage";
import { ColumnBox } from "../common/ColumnBox";
import { TextFormField } from "../fields/TextFormField";

const schema = z.object({
  reason: z
    .string()
    .min(1, { message: "Reason is required." })
    .max(500, { message: "Must be 500 characters or less." }),
});

type ReportEventFormProps = {
  onFormSubmit: (reason: string) => void;
};

export const ReportEventForm = ({ onFormSubmit }: ReportEventFormProps) => {
  const formMethods = useFormWithValidation({
    schema: schema,
    onSubmit: async (data) => {
      onFormSubmit(data.reason);
    },
  });

  const {
    handleSubmit,
    onSubmit,
    formState: { isSubmitting, errors },
  } = formMethods;

  return (
    <Box>
      <FormProvider {...formMethods}>
        <LoadingOverlay show={isSubmitting} />

        <form onSubmit={handleSubmit(onSubmit)}>
          <ColumnBox>
            {errors?.root && <ErrorMessage message={errors.root?.message} />}
            <TextFormField
              type="text"
              label="Reason"
              name="reason"
              rows={3}
              multiline
              sx={{ width: "100%" }}
              error={errors.reason}
            />
            <Button variant="contained">Report</Button>
          </ColumnBox>
        </form>
      </FormProvider>
    </Box>
  );
};
